@import "../variables.scss";

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.item {
    flex-grow: 1;
    flex-shrink: 1;
    border-right: 1px solid $borderColor;

    &:last-child {
        border-right: none;
    }
}
