.layout {
    height: 100%;
    :global(.k-grid-header) {
        > tr th {
            padding: 2px;
            @media all and (min-width: 600px) {
                padding-left: 7px;
            }
        }
    }
    :global(.k-grid) {
        > table tbody tr td {
            padding: 2px;
            @media all and (min-width: 600px) {
                padding-left: 7px;
            }
        }
    }
    :global(.k-grid-footer) {
        > tr td {
            padding: 2px;
        }
    }
    :global(.k-grid) {
        font-size: 11px;
        > table tbody tr td {
            max-width: 0;
        }
        > table tbody tr td:first-child {
            text-overflow: clip;
        }
    }
}

.layout input {
    width: inherit !important;
}

.cellNumber {
    text-align: right;
    padding-right: 6px;
}

.row {
    display: flex;
    flex-direction: row;
}

.nameRow {
    padding-left: 2px;
    @media all and (min-width: 600px) {
        padding-left: 7px;
    }
}

.colL {
    width: 50%;
    padding-right: 0.5rem;
    box-sizing: border-box;
    padding-left: 2px;
    @media all and (min-width: 600px) {
        padding-left: 7px;
    }
}

.colR {
    width: 50%;
    padding-left: 0.5rem;
    box-sizing: border-box;
}

.labels {
    padding: 3px;
    padding-left: 5px;
    font-size: small;
}

.larger {
    padding: 3px;
    font-size: large;
}

.signSpinnerBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5px;
}

.signSpinner {
    font-size: 2rem;
}

.dialogStyle div div div {
    white-space: normal;
}

.stamp {
    z-index: 1;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50vh;
}

@keyframes pulsing {
    from {
        background-color: transparent;
        color: inherit;
    }
    50% {
        background-color: orange;
        color: black;
    }
    to {
        background-color: transparent;
        color: inherit;
    }
}

.signButton {
    border-radius: 0;

    animation-name: pulsing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.z {
    z-index: 2;
}

.nodata {
    text-align: center;
    line-height: 3;
}

.noteRow {
    display: flex;
    padding: 2px;
    align-items: center;

    @media all and (min-width: 600px) {
        padding-inline: 7px;
    }

    span {
        padding: 2px;
    }
}

.worksheetLayout {
    position: relative;
}
