.notification {
    width: 100%;
    > :global(.k-notification-container) {
        width: 100%;
        > :global(.k-notification) {
            width: 100%;
            > :global(.k-notification-wrap) {
                > :global(.k-notification-content) {
                    :global(.k-panelbar) {
                        background-color: inherit;
                        border-style: none;
                        :global(.k-item > .k-link.k-state-selected) {
                            color: black;
                            background-color: inherit;
                            border-style: none;
                        }
                    }
                }
            }
        }
    }
}
