body {
    margin: 0;
}

iframe {
    display: block;
    background: #000;
    border: none;
    height: 100vh;
    width: 100vw;
}

.paramRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 36px;

    > label {
        margin-right: 25px;
        vertical-align: middle;
    }

    > input,
    > div,
    > span {
        width: 80%;
        float: right;
    }

    > .check {
        width: 50%;
    }
}
