.contentContainer {
    padding: 1rem;
}

.buttonsContainer {
    > button {
        height: 50px;
        width: 100%;
        margin-bottom: 1rem;
        justify-content: flex-start !important;

        > span:first-child {
            margin-left: 20px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.badge {
    margin-left: 1rem;
}

.terminals {
    background-color: #ffbcaf;
    color: black;
}

.mb1 {
    margin-bottom: 1rem;
}

.grid {
    display: flex;
    flex-direction: column;

    > .col1 {
        margin-bottom: 1rem;
        width: 100%;
    }

    > .col2 {
        width: 100%;
    }

    @media (min-width: 600px) {
        & {
            flex-direction: row-reverse;

            > .col1 {
                margin-left: 0.5rem;
            }

            > .col2 {
                margin-right: 0.5rem;
            }
        }
    }
}

.icon {
    font-size: 25px;
    font-style: normal;
    margin-right: 20px;
}
