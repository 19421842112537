.panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: lightblue;
    border-radius: 5px;
    padding: 1rem;

    > .info {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

.gutterBottom {
    margin-bottom: 0.35rem;
}

.textPerson {
    font-size: 1.25rem;
    margin-top: 0;
}
