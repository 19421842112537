.fieldset {
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
    border: 0;
}

.changeCode {
    width: fit-content;
    margin: auto;
    text-align: center;
    cursor: pointer;
    opacity: 0.5;
    padding-top: 0.5em;
}
