.header {
    display: flex;
    align-items: center;
}

.heading {
    flex-grow: 1;
    padding-left: 10px;
}

.lang > span {
    width: 100%;
}

.lang > span > span {
    border-style: hidden !important;
}
