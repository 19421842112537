.root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.checkboxContainer {
    padding: 0.4rem 0;
}

.nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 0;
    gap: 0.2rem;
    overflow-x: auto;
}

.selected {
    background-color: orange !important;
    color: black !important;
}

ul.list {
    flex-grow: 1;
    padding: 0;
    border: 2px solid #3f51b5;
    overflow: auto;
}
