.table {
    margin-left: 1rem;

    .title {
        padding-left: 1rem;
    }

    .colorCell {
        width: 40px;
        border: 1px solid lightgray;
    }
}
