.ellipsis {
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
