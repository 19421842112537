.icon > span {
    font-size: x-large;
}

.noData {
    font-size: x-large;
    margin: 15px;
}

.firstRow {
    display: flex;
    //margin: 5px;
    :last-child {
        margin-left: 15px;
    }
}
