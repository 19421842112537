.shortHeading {
    @media screen and (min-width: 330px) {
        display: none;
    }
}

.longHeading {
    @media screen and (max-width: 329px) {
        display: none;
    }
}

.personInfoContainer {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > * {
        flex-grow: 1;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.dayOfWeek {
    @media screen and (max-width: 599px) {
        display: none;
    }
}
