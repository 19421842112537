.stack {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    margin-bottom: 0.25rem;

    overflow-x: hidden;

    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}
