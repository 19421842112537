@import "../variables.scss";

.container {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}

.item {
    height: 100%;
    flex-grow: 1;
    border-right: 1px dashed $borderColor;
}
