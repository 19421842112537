ul[role="menu"] {
    width: 200px;
}

li[role="menuItem"] > a {
    color: inherit;
    text-decoration: none;
}

.params :global(.k-dropdown) {
    width: 100%;
}

.params {
    background-color: lightblue;
    padding: 8px;
    display: flex;
    flex-direction: column;

    > button {
        flex: 1;
    }

    > span {
        flex: 1;
        padding-right: 15px;
        padding-left: 10px;
    }

    @media (min-width: 600px) {
        & {
            flex-direction: row;
        }
    }
}

.dropdown :global(.k-dropdown) {
    width: 100%;
}

.centerButton {
    background-color: orange !important;
    color: black !important;
}

.params > span > span > span {
    margin-left: 0.5rem;
}

.noData {
    font-size: x-large;
    margin: 15px;
}
