.dialogTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.dialogTitle > span {
    font-size: x-large;
    margin-right: 20px;
}

.accountName {
    @extend %noWrapText;
    font-size: large;
    font-weight: bold;
}

.created {
    color: blue;
}

.aprooved {
    color: green;
}

.rejected {
    color: red;
}

.icon > span {
    font-size: x-large;
}

.searchBar {
    width: 120px;
    color: lightgray;
}

.searchBar::placeholder {
    color: inherit;
}

.noData {
    font-size: x-large;
    margin: 15px;
}

.datePicker {
    display: flex;

    > label,
    > span,
    > div {
        width: 50%;
    }
}

%noWrapText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.noWrapText {
    @extend %noWrapText;
}

.checkbox {
    margin: auto;
}

.footerCount {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    align-items: center;
    display: flex;
}

.footerCount > span {
    text-align: center;
    width: 100%;
}

.checkboxIn {
    transition: opacity 0.3s 0.3s, min-width 0.3s 0s;
    opacity: 1;
}

.checkboxHidden {
    transition: min-width 0.3s 0s;
    opacity: 0;
}

.checkboxOut {
    transition: opacity 0.3s 0s, min-width 0.3s 0.3s;
    opacity: 0;
    min-width: 0px !important;
}

.navBarElementIn {
    transition: opacity 0.5s;
    opacity: 1 !important;
    visibility: visible;
    max-width: 500px;
}

.navBarElementOut {
    transition: opacity 0.5s;
    opacity: 0 !important;
    visibility: collapse;
    max-width: 0px;
    padding: 0px !important;
}

%footer {
    background: white;
    box-shadow: 0px -3px 3px 2px #0001;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.footerIn {
    @extend %footer;
    transition: max-height 0.3s 0s, visibility 0s 0s;
    max-height: 500px;
    visibility: visible;
}

.footerOut {
    @extend %footer;
    box-shadow: 0px -3px 3px 2px #0001;
    transition: max-height 0.3s 0s, visibility 0s 0.3s;
    max-height: 0px;
    visibility: collapse;
}
