ul[role="menu"] {
    width: 200px;
}

li[role="menuItem"] > a {
    color: inherit;
    text-decoration: none;
}

.params :global(.k-dropdown) {
    width: 100%;
}

.params {
    background-color: lightblue;
    padding: 8px;
    display: flex;
    flex-direction: column;
    z-index: 20;

    > button {
        flex: 1;
    }

    > span {
        flex: 1;
        padding-right: 15px;
        padding-left: 10px;
    }

    @media (min-width: 600px) {
        & {
            flex-direction: row;
        }
        position: absolute;
        margin-top: 0px;
        width: calc(100% - 16px);
    }
}

.dropdown :global(.k-dropdown) {
    width: 100%;
}

.centerButton {
    background-color: orange !important;
    color: black !important;
}

.params > span > span > span {
    margin-left: 0.5rem;
}

.noData {
    font-size: x-large;
    margin: 15px;
}

.tabs {
    position: absolute;
    left: 0;
    background-color: white;
    bottom: 0;
    height: 50px;
    width: 100%;
    :global(.MuiTabs-indicator) {
        bottom: 48px;
    }
    @media (min-width: 600px) {
        top: 100px;
        //margin-top: 100px;
        :global(.MuiTabs-indicator) {
            bottom: 0px;
        }
    }
}

.tabBodies {
    position: relative;
    top: 0px;
    padding-bottom: 50px;
    @media (min-width: 600px) {
        top: 102px;
        padding-bottom: 0px;
    }
}

.withoutTabs {
    position: relative;
    top: 0px;
    padding-bottom: 0px;
    @media (min-width: 600px) {
        top: 50px;
        padding-bottom: 0px;
    }
}
