.layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin: auto;

    @media all and (min-width: 500px) {
        width: 500px;
    }
}

.layout button {
    width: calc(50% - 10px);
    flex-grow: 1;
    margin: 5px;
    height: 120px;
    white-space: normal;
}

.layout :global(.k-dropdown) {
    width: 100%;
    min-width: 200px;
    max-width: 500px;
    padding-top: 20px;
    padding-bottom: 15px;
}

.settingsLayout {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    @media all and (min-width: 500px) {
        width: 500px;
    }
}

.settingsLayout div div {
    width: calc(48% - 1px);
    flex-grow: 1;
    margin: 2px;
    height: 60px;
    display: inline-block;
    border-style: solid;
    border-width: 2px;
    border-radius: 4%;
    border-color: black;
}

.settingsLayout :global(.k-dropdown) {
    width: 100%;
    min-width: 150px;
    max-width: 300px;
    margin: 5px;
    margin-top: 10px;
    padding-right: 10px;
}

.settingsLayout > div > div > button {
    width: 100%;
    height: 100%;
}

.redColor {
    background-color: red;
}

.greenColor {
    background-color: #4caf50;
}

.grayColor {
    background-color: #c4c4c4;
}

.z {
    z-index: 1;
}

.timer {
    font-size: x-large;
    text-align: center;
    width: 100%;
    font-weight: bold;
}
