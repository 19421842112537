.root {
    height: 80px;
    padding: 10px;
}

.dropdown :global(.k-dropdown) {
    width: 100%;
    min-width: 150px;
    max-width: 800px;
    //margin: 5px;
    margin-top: 10px;
    padding-right: 10px;
}

.description {
    font-size: 1.5em;
}
