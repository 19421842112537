.toolbar {
    width: 100%;
    flex-wrap: nowrap;
    color: white;

    &.primary {
        background-color: #3f51b5;
    }

    > :global(.k-button) {
        color: inherit;

        &:global(.k-state-disabled) {
            color: rgba(255, 255, 255, 0.38);
        }
    }

    > :global(.k-dropdown-button) {
        color: inherit;

        > :global(.k-button) {
            color: inherit;
        }
    }
}

.heading {
    margin-left: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popup {
    border-radius: 5px !important;
}

.popup * {
    white-space: nowrap !important;
}
