@import "../variables.scss";

.cell {
    border: 1px solid $borderColor;
    border-right-width: $widerBorderWidth;
    border-bottom-width: $widerBorderWidth;
    overflow: hidden;
    background-color: $evenRowColor;

    top: 0px;
    position: sticky;
}
