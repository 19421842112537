.contentContainer {
    padding: 1rem;
}

.avatar {
    display: block;
    margin: auto;
    height: 196px;
    max-height: 196px;
    max-width: 100%;
}

.row {
    display: flex;
    flex-direction: row;
}

.colL {
    width: 50%;
    padding-right: 0.5rem;
    box-sizing: border-box;
}

.colR {
    width: 50%;
    padding-left: 0.5rem;
    box-sizing: border-box;
}
