@import "../variables.scss";

.container {
    border-bottom: 1px solid $borderColor;
    overflow: hidden;
}

.textAlignCenter {
    text-align: center;
}
