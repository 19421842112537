.layout {
    display: flex;
    flex-direction: column;

    margin: auto;

    @media all and (min-width: 500px) {
        width: 500px;
    }

    & > .body {
        flex-grow: 1;
        margin: 0 1rem;
    }
}

.layout form {
    margin: 0 1rem;
}

.layout > div {
    margin: 7px 1rem;
}

.fieldset {
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
    border: 0;
}
