@keyframes orangePulsing {
    from {
        background-color: transparent;
        color: inherit;
    }
    50% {
        background-color: orange;
        color: black;
    }
    to {
        background-color: transparent;
        color: inherit;
    }
}

.signButton {
    border-radius: 0;

    animation-name: orangePulsing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes redPulsing {
    from {
        background-color: transparent;
        color: inherit;
    }
    50% {
        background-color: red;
        color: white;
    }
    to {
        background-color: transparent;
        color: inherit;
    }
}

.changeButton {
    border-radius: 0;

    animation-name: redPulsing;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.scrollable {
    height: 100%;
    overflow: auto;
}

.noteRow {
    display: flex;
    padding: 2px;
    align-items: center;

    @media all and (min-width: 600px) {
        padding-inline: 7px;
    }

    span {
        padding: 2px;
    }
}
