.paramRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 36px;

    > label {
        margin-right: 25px;
        vertical-align: middle;
    }

    > .check {
        width: 50%;
    }
}
