html {
    height: 100%;
    overflow: hidden;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
    overflow: auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Dočasně řešení problému kdy se dropdown zobrazuje pod dialogem:
 * https://github.com/telerik/kendo-react/issues/45
 * https://www.telerik.com/kendo-react-ui/components/popup/stack-order/
 */
body .k-animation-container {
    z-index: 10003;
}

/* Je potřeba při renderování odsazeného menu v NavBaru.
 * Jinak se zobrazí stín i v odsazené oblasti.
 */
body .k-animation-container-shown {
    box-shadow: none;
}

body .k-textbox-container {
    width: 100%;
}

/* Prevent Kendo Dialog overflow out of view. */
body .k-dialog {
    max-width: 600px;
}

.app-bar-heading {
    color: lightgray;
    font-size: 1.25rem;
}

.text-primary {
    font-size: 16px;
    color: black;
}

.text-secondary {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
}

.info-group-heading {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.list-item-heading {
    font-size: 18px;
    font-weight: bold;
    color: black;
}

.list-item-text-primary {
    font-size: 14px;
    color: black;
}

.list-item-text-secondary {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
}

.page-container {
    margin: 1rem;
}

.section-container {
    margin: 0.75rem;
}

.w-100 {
    width: 100%;
}

@font-face {
    font-family: "AnetFont";
    src: local("AnetFont"), url(./fonts/anetfont.ttf) format("truetype");
}

[class^="anetFont-"]:before,
[class*=" anetFont-"]:before,
[class^="anetFont-"]:after,
[class*=" anetFont-"]:after {
    font-family: AnetFont;
    font-size: 25px;
    font-style: normal;
    margin-right: 20px;
}

.anetFont-presence:before {
    content: "\e9b9";
}
.anetFont-workflow:before {
    content: "\e9cf";
}
.anetFont-worksheet:before {
    content: "\e997";
}
.anetFont-plan:before {
    content: "\e97f";
}
.anetFont-reports:before {
    content: "\e9bf";
}
.anetFont-worksheet-states:before {
    content: "\e993";
}
.anetFont-overtime:before {
    content: "\e92a";
}

.k-window {
    max-height: 90vh;
}
