.window div div {
    padding: 3px;
}

.flex {
    display: flex;
    flex-direction: row;
    border-bottom: solid;
    border-width: 1px;
    border-color: black;
}

.flex > button {
    box-shadow: none;
}

.buttons {
    float: right;
}

.monthButt {
    flex-grow: 4;
}
