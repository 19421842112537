@import "./variables.scss";

.container * {
    box-sizing: border-box;
}

// sudý
.even {
    background-color: $evenRowColor;
}

.intervalsFetching {
    background: #f2f2f2;
    font-style: italic;
}

.intervalsNotRecalculated {
    font-style: italic;
}
