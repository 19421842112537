.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contentContainer {
    flex-grow: 1;
    overflow: auto;
}

.notificationTop {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
}

%sideButton {
    position: absolute;
    top: 50%;
    border-radius: 50%;
    z-index: 2;
}

.previousButton {
    @extend %sideButton;
    left: 1.5rem;
}

.nextButton {
    @extend %sideButton;
    right: 1.5rem;
}
