.layout {
    height: 100%;
    display: flex;
    flex-direction: column;

    margin: auto;

    @media all and (min-width: 500px) {
        width: 500px;
    }

    & > .body {
        flex-grow: 1;
        margin: 0 1rem;
    }
}
