.params :global(.k-dropdown) {
    width: 100%;
}

.params {
    background-color: lightblue;
    padding: 8px;
    display: flex;
    flex-direction: column;

    > button {
        flex: 1;
    }

    > span {
        flex: 1;
        padding-right: 15px;
        padding-left: 10px;
    }

    @media (min-width: 600px) {
        & {
            flex-direction: row;
        }
    }
}

.params > span > span > span {
    margin-left: 0.5rem;
}

.centerButton {
    background-color: orange !important;
    color: black !important;
}

.green {
    color: green;
}

.gray {
    color: gray;
}

.red {
    color: red;
}

.noData {
    font-size: x-large;
    margin: 15px;
}
