.pickingRow {
    display: flex;

    label {
        width: 50px;
        margin-top: auto;
        margin-bottom: auto;
    }

    :global(.k-datepicker) {
        width: 120px;
        margin-right: 10px;
    }

    :global(.k-timepicker) {
        width: 100px;
    }
}

.checkboxRow {
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dropdownList :global(.k-dropdown) {
    width: 100%;
    min-width: 250px;
    max-width: 300px;
}

.z {
    z-index: 2;
}
